<template>
  <div>
    <PageHeader :title="'S3 5 Minutos'" :items="breadcrumb" />

    <b-card class="mb-3 mt-3">
      <b-container fluid class="ml-3 mt-2">
        <b-row align-v="center" class="my-3">
          <b-col md="4">
            <b-form-group label="Mês de Referência" label-for="filtroMesRef">
              <b-form-select
                id="filtroMesRef"
                v-model="filtroMesRef"
                :options="meses"
                @change="atualizarDataRef"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Ano de Referência" label-for="filtroAnoRef">
              <b-form-input
                id="filtroAnoRef"
                type="number"
                v-model="filtroAnoRef"
                @change="atualizarDataRef"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" class="d-flex justify-content">
            <b-button @click="fetchData" variant="success" class="mt-2"
              >Buscar</b-button
            >
            <b-spinner
              v-if="loading"
              text-align="center"
              variant="success"
              label="Spinning"
              class="ml-2 mt-3"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <DataQualityTable :dadosMes="dadosMes" :loading="loading" :dados="dados" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { listAll } from './s3_5minutos.service';
import meses from '@/utils/meses';
import JsonExcel from 'vue-json-excel';
import DataQualityTable from './s3Table';

Vue.use(VueMask);
Vue.component('downloadExcel', JsonExcel);

export default {
  data() {
    return {
      mesRefInput: '',
      dados: [],
      dadosMes: null,
      meses: meses(),
      mesRef: '',
      filtroMesRef: new Date().getMonth() + 1,
      filtroAnoRef: new Date().getFullYear(),
      loading: false,
      breadcrumb: [
        {
          text: 'CCEE',
          href: '#'
        },
        {
          text: 'MED5m',
          href: '#'
        },
        {
          text: 'S3',
          active: true
        }
      ]
    };
  },
  computed: {
    tableData() {
      return this.dados.map((dado) => ({
        ...dado,
        arquivosValidados: dado.arquivosValidados.quantidade,
        arquivosNaoValidados: dado.arquivosNaoValidados,
        arquivosFaltantes: dado.arquivosFaltantes
      }));
    }
  },
  components: {
    DataQualityTable,
    PageHeader
  },
  created() {
    this.atualizarDataRef();
  },
  methods: {
    atualizarDataRef() {
      if (this.filtroMesRef && this.filtroAnoRef) {
        const mesIndexZeroBase = this.filtroMesRef - 1;
        this.mesRefInput = `${this.filtroAnoRef}-${String(
          mesIndexZeroBase + 1
        ).padStart(2, '0')}-01`;
      }
    },
    fetchData() {
      this.loading = true;

      listAll(this.mesRefInput)
        .then((response) => {
          console.log(response);
          if (response && response.success && response.content) {
            const { content } = response;
            this.dados = content.dados;
            this.dadosMes = {
              diasEsperados: content.diasEsperados,
              tamanhoEsperado: content.tamanhoEsperado,
              mesRef: content.mesRef
            };
          } else {
            console.error(
              'Resposta do servidor não está no formato esperado:',
              response
            );
            this.dados = [];
            this.dadosMes = null;
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar dados:', error);
          this.dados = [];
          this.dadosMes = null;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
