var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dadosMes && !_vm.loading)?_c('b-card',[_c('b-container',{staticClass:"ml-3 mt-2",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"5"}},[_c('h5',[_vm._v("Mês de Referência: "+_vm._s(_vm._f("formatDate")(_vm.dadosMes.mesRef)))])])],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h5',[_vm._v("Dias Esperados: "+_vm._s(_vm.dadosMes.diasEsperados))])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('h5',[_vm._v(" Tamanho Esperado: "+_vm._s(_vm._f("formatNumber")(_vm.dadosMes.tamanhoEsperado))+" KB ")])]),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"2"}},[_c('download-excel',{attrs:{"data":_vm.dados,"fields":_vm.jsonFields,"type":"xls","name":"dataQuality.xls"}},[_c('b-button',{attrs:{"variant":"success"}},[_vm._v("Exportar para Excel")])],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"items":_vm.tableData,"fields":_vm.tableFields,"striped":true,"responsive":"sm","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"tbody-tr-class":_vm.rowClass},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(arquivosNaoValidados)",fn:function(row){return [(row.item.arquivosNaoValidados > 0)?_c('a',{class:_vm.getClassForValue(
                    row.item.arquivosNaoValidados,
                    'naoValidados'
                  ),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(
                    row.item.arquivosNaoValidados.datas,
                    'Arquivos Não Validados'
                  )}}},[_vm._v(" "+_vm._s(row.item.arquivosNaoValidados)+" ")]):_c('span',{class:_vm.getClassForValue(
                    row.item.arquivosNaoValidados,
                    'naoValidados'
                  )},[_vm._v(" "+_vm._s(row.item.arquivosNaoValidados)+" ")])]}},{key:"cell(arquivosFaltantes)",fn:function(row){return [(row.item.arquivosFaltantes > 0)?_c('a',{class:_vm.getClassForValue(row.item.arquivosFaltantes, 'faltantes'),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(row.item)}}},[_vm._v(" "+_vm._s(row.item.arquivosFaltantes)+" ")]):_c('span',{class:_vm.getClassForValue(row.item.arquivosFaltantes, 'faltantes')},[_vm._v(" "+_vm._s(row.item.arquivosFaltantes)+" ")])]}},{key:"cell(arquivosValidados)",fn:function(row){return [_c('span',{class:_vm.getClassForValue(row.item.arquivosValidados, 'validados')},[_vm._v(" "+_vm._s(row.item.arquivosValidados)+" ")])]}}],null,false,1934656354)})],1)],1)],1)],1):_vm._e(),_c('b-modal',{attrs:{"title":_vm.modalTitle,"size":"lg","centered":"","hide-footer":""},model:{value:(_vm.showModalFlag),callback:function ($$v) {_vm.showModalFlag=$$v},expression:"showModalFlag"}},[_c('b-list-group',_vm._l((_vm.modalData),function(date){return _c('b-list-group-item',{key:date},[_vm._v(" "+_vm._s(date)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }