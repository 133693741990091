<template>
  <div>
    <b-card v-if="dadosMes && !loading">
      <!-- Conteúdo do segundo b-card aqui -->
      <b-container fluid class="ml-3 mt-2">
        <b-row>
          <b-col cols="12" md="5" class="mb-1">
            <h5>Mês de Referência: {{ dadosMes.mesRef | formatDate }}</h5>
          </b-col> </b-row
        ><b-row>
          <b-col cols="12" class="mb-1">
            <h5>Dias Esperados: {{ dadosMes.diasEsperados }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <h5>
              Tamanho Esperado: {{ dadosMes.tamanhoEsperado | formatNumber }} KB
            </h5>
          </b-col>
          <b-col cols="2" class="mb-3">
            <download-excel
              :data="dados"
              :fields="jsonFields"
              type="xls"
              name="dataQuality.xls"
            >
              <b-button variant="success">Exportar para Excel</b-button>
            </download-excel>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :items="tableData"
              :fields="tableFields"
              :striped="true"
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :tbody-tr-class="rowClass"
            >
              <template v-slot:cell(arquivosNaoValidados)="row">
                <a
                  v-if="row.item.arquivosNaoValidados > 0"
                  href="#"
                  @click.prevent="
                    showModal(
                      row.item.arquivosNaoValidados.datas,
                      'Arquivos Não Validados'
                    )
                  "
                  :class="
                    getClassForValue(
                      row.item.arquivosNaoValidados,
                      'naoValidados'
                    )
                  "
                >
                  {{ row.item.arquivosNaoValidados }}
                </a>
                <span
                  v-else
                  :class="
                    getClassForValue(
                      row.item.arquivosNaoValidados,
                      'naoValidados'
                    )
                  "
                >
                  {{ row.item.arquivosNaoValidados }}
                </span>
              </template>

              <!-- Customizando a coluna de arquivos faltantes -->
              <template v-slot:cell(arquivosFaltantes)="row">
                <a
                  v-if="row.item.arquivosFaltantes > 0"
                  href="#"
                  @click.prevent="
                    showModal(row.item)
                  "
                  :class="
                    getClassForValue(row.item.arquivosFaltantes, 'faltantes')
                  "
                >
                  {{ row.item.arquivosFaltantes }}
                </a>
                <span
                  v-else
                  :class="
                    getClassForValue(row.item.arquivosFaltantes, 'faltantes')
                  "
                >
                  {{ row.item.arquivosFaltantes }}
                </span>
              </template>
              <template v-slot:cell(arquivosValidados)="row">
                <span
                  :class="
                    getClassForValue(row.item.arquivosValidados, 'validados')
                  "
                >
                  {{ row.item.arquivosValidados }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Modal de Detalhes -->
    <b-modal
      v-model="showModalFlag"
      :title="modalTitle"
      size="lg"
      centered
      hide-footer
    >
      <b-list-group>
        <b-list-group-item v-for="date in modalData" :key="date">
          {{ date }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mesRefInput: '',
      sortBy: 'pontoMedicao',
      sortDesc: false,
      showModalFlag: false,
      modalData: [],
      modalTitle: '',
      tableFields: [
        { key: 'pontoMedicao', label: 'PM', sortable: true },
        {
          key: 'arquivosValidados',
          label: 'ARQUIVOS VALIDADOS',
          sortable: true
        },
        {
          key: 'arquivosNaoValidados',
          label: 'ARQUIVOS NÃO VALIDADOS',
          sortable: true
        },
        {
          key: 'arquivosFaltantes',
          label: 'ARQUIVOS FALTANTES',
          sortable: true
        }
      ],
      jsonFields: {
        PontoMedicao: 'pontoMedicao',
        ArquivosValidadosQuantidade: 'arquivosValidados.quantidade',
        ArquivosValidadosDatas: 'arquivosValidados.datas',
        ArquivosNaoValidadosQuantidade: 'arquivosNaoValidados.quantidade',
        ArquivosNaoValidadosDatas: 'arquivosNaoValidados.datas',
        ArquivosFaltantesQuantidade: 'arquivosFaltantes.quantidade',
        ArquivosFaltantesDatas: 'arquivosFaltantes.datas'
      }
    };
  },
  props: {
    dadosMes: Object,
    loading: Boolean,
    dados: Array
  },
  methods: {
    getClassForValue(value, type) {
      if (type === 'validados' && value > 0) {
        return 'text-success'; // Classe do Bootstrap para texto verde
      } else if (type === 'naoValidados' && value > 0) {
        return 'text-warning'; // Classe do Bootstrap para texto laranja
      } else if (type === 'faltantes' && value > 0) {
        return 'text-danger'; // Classe do Bootstrap para texto vermelho
      }
      return ''; // Retorna uma string vazia se nenhuma condição for atendida
    },
    showModal(datas) {
      this.modalData = datas.datasFaltantes;
      this.modalTitle = "Arquivos Faltantes:";
      this.showModalFlag = true;
    },
    rowClass(item) {
      if (item.arquivosFaltantes > 0) {
        return 'table-danger';
      } else if (item.arquivosNaoValidados > 0) {
        return 'table-warning';
      }
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      // Certifique-se de que a data esteja no formato esperado (YYYY-MM-DD)
      const parts = value.split('-');
      const year = parts[0];
      const month = parts[1];
      // Cria um novo objeto Date usando ano e mês (ajustando o mês para índice baseado em zero)
      const date = new Date(year, month - 1);
      return date.toLocaleDateString('pt-BR', {
        month: 'long',
        year: 'numeric'
      });
    },
    formatNumber(value) {
      if (!value) return '0';
      // Converte o valor para um número e depois para uma string formatada
      return Number(value).toLocaleString('pt-BR');
    }
  },
  computed: {
    tableData() {
      const mappedData = this.dados.map((dado) => ({
        ...dado,
        arquivosValidados: dado.arquivosValidados.quantidade,
        arquivosNaoValidados: dado.arquivosNaoValidados.quantidade,
        arquivosFaltantes: dado.arquivosFaltantes.quantidade,
        datasFaltantes: dado.arquivosFaltantes.datas
      }));
      return mappedData;
    }
  }
};
</script>
